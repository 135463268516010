<template>
    <div>
        <!-- <div class="wrap" v-loading="loading"> -->
			<div class="page_container" style="height:80vh">
				<a-row :gutter="24" type="flex" align="stretch" style="height: 100%; flex: 1">
					<a-col :span="24">
						<a-card :bordered="false" class="header-solid h-full" style="box-shadow: none" :bodyStyle="{ background:'radial-gradient(circle at 12% 55%, rgba(33, 150, 243, 0.15), hsla(0, 0%, 100%, 0) 25%), radial-gradient(circle at 85% 33%, rgba(108, 99, 255, 0.175), hsla(0, 0%, 100%, 0) 25%)',height: '100%'}">
				
						<a-col :span="6" ><div class="left">
							<div class="l_box l_box1">
								<div class="l_box_word">歌词

								</div>
								<div>
									<a-textarea placeholder="输入你自己的歌词" allow-clear v-model="prompt" :rows="6" />
								</div>
								<div><a-button class="create" block :loading="loading"  @click="lyrics">① 生成歌词</a-button></div>
							</div>
							<div class="l_box l_box2">
								<div class="l_box_word">音乐风格</div>
								<div>
									<a-textarea placeholder="输入音乐风格" allow-clear v-model="tags" :rows="2" />
								</div>
							</div>
							<div class="l_box l_box3">
								<div class="l_box_word">标题</div>
								<div>
									<a-input placeholder="输入标题" v-model="title" />
								</div>
							</div>
							<div class="l_box l_box4">
								<a-button :loading="loading" class="create" block  @click="create">② 开始创作</a-button>
							</div>
							<span style="font-size: 10px;color:red">*注意：点击生成后大概需要3分钟左右完成创作，请耐心等待，不要退出。({{usable}}积分/次，会员免费)</span><br>
						</div>
						</a-col><a-col :span="10" >
						<div class="main">
							<div class="content" style="height: 80vh;overflow-x: auto;">
								<div class="song_box item"  v-for="(item, index) in song_data" :key="index" @click="song_one(item)"  :style="{ backgroundColor: hoveredIndex === index ? '#D9D9D9FF' : 'white' }"

									 @mouseenter="hoveredIndex = index"

									 @mouseleave="hoveredIndex = -1"  >
									<div class="song_box_img">
										<img :src="item.image_url">
									</div>
									<div class="song_box_content">
										
										<div>
											{{item.title}}
										</div>
										<div>
											{{item.tags}}
										</div>
										<div style="font-size: 12px;color: #ccc;">
											{{item.created_at}}--suno mode:V3
										</div>
									</div>
								</div>
							</div>

						</div>
						</a-col><a-col :span="8" >
						<div class="right">
							<h3 style="text-align: center" v-if="one_song == ''">请选择左侧曲目</h3>
							<div class="content">
								<div class="right_img">
									<img :class="{ 'rotate': isPlaying }" :src="one_song.image_url">
								</div>
								<div class="right_box1"  v-if="one_song.title">
									<div class="right_word1"><strong>{{one_song.title}}</strong><br>【{{one_song.tags}}】</div>
									<audio style="width: 100%;margin-top: 5px;" @play="startRotation"
									       @pause="stopRotation"
									ref="audioElement" v-if="one_song.audio_url" :src="one_song.audio_url" controls>
									</audio>
									
									<a-textarea :value="one_song.prompt"   style="height: 25vh;margin-top: 10px;"/>
								</div>
								<!-- <div class="right_box2">
									{{one_song.prompt}}
								</div> -->
								
							</div>
						</div>
						</a-col>
			            </a-card>
			    	</a-col>
			    </a-row>	
		</div>
        

    </div>

</template>

<script setup>
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            prompt:'',
            tags:'',
            title:'',
            loading:false,
            btn_loading:false,
            load_desc:"生成中",
            lid:'',
            song_id:'98531a08-d46f-4e59-a6ab-558cac04f44a',
            song_data:[],
            hoveredIndex: -1,
            one_song:[],
			isPlaying: false,
            usable:100

        };
    },
    watch: {


    },
    created() {
        // this.getSongResult()
        this.getData()
        this.getConfig()
    },
    mounted() {
		
   
    },
	 
    computed:{
        ...mapGetters("user", ["token", "userInfo"]),
    },
    methods: {
        ...mapActions("user", ["getUserInfo"]),
        song_one(item){
            this.one_song = item;
            // console.log(item);
        },
		startRotation() {
		      this.isPlaying = true;

		    },
		    stopRotation() {
		      this.isPlaying = false;

		    },
        // handleMouseEnter(index){
        //     console.log(111);
        //
        // },
        // handleMouseLeave(index){
        //     console.log(222);
        // },
        lyrics(){
            if(this.prompt == ''){
                this.$message.error('请输入歌词');
                return false
            }
            this.loading = true;
            this.$http("song.lyrics", { prompt:this.prompt }).then(res => {
                if(res.code == 1){
                    console.log(res);
                    this.lid = res.data;

                    // this.getlyricsResult();
                    const timer = setTimeout(() => {
                        this.getlyricsResult();
                        clearTimeout(timer)
                    }, 5000)
                }else{
					this.loading = false;
				}

                
            })
        },
        getData(){
            this.$http("song.getData").then(res => {
                if(res.code == 1){
                    this.song_data = res.data;
                    // console.log(res);


                }


            })
        },
        getConfig(){
            this.$http("song.getConfig").then(res => {
                if(res.code == 1){
                    this.usable = res.data.usable;
                    // console.log(res);


                }


            })
        },
        getlyricsResult(){
            this.$http("song.getlyricsResult", { lid:this.lid }).then(res => {
                if(res.code == 1){
                    this.loading = false;
                    this.prompt = res.data.text;
                    this.title = res.data.title;
                    console.log(res);
                }else{
					this.$message.success('正在生成中，请耐心等待...')
                    const timer = setTimeout(() => {
                        this.getlyricsResult()
                        clearTimeout(timer)
                    }, 5000)
                }




            })
        },
        create(){
            this.loading = true;
            this.$http("song.create", { prompt:this.prompt,tags:this.tags,title:this.title }).then(res => {
                if(res.code == 1){
                    this.song_id = res.data;
                    const timer = setTimeout(() => {
                        this.getSongResult();
                        clearTimeout(timer)
                    }, 20000)
                }else{
					this.loading = false;
				}
            })
        },
        getSongResult(){
            this.$http("song.getSongResult", { song_id:this.song_id }).then(res => {
                if(res.code == 1){
                    this.loading = false;
                    this.getData();
                    console.log(res);
                }else{
					this.$message.success('正在生成中，请耐心等待...')
                    const timer = setTimeout(() => {
                        this.getSongResult()
                        clearTimeout(timer)
                    }, 5000)
                }
            })

        }

    },

};


</script>
<style scoped>
.item {

    padding: 10px;

    margin: 5px;


    cursor: pointer;

    transition: background-color 0.3s ease;

}
.wrap{
    display: flex;
    /*align-items: center;*/
    justify-content: center;
}
.wrap .left{
    padding: 15px;
    width: 25%;
    border-right: 1px solid #D9D9D9FF;
}
.left .l_box{
    margin-bottom: 20px;
}
.left .l_box4{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.left .create{
	margin-top: 5px;
    background-color: #55aaff;
	color: #fff;
}

.wrap .main{
	/* max-height: 80vh;
	overflow: auto; */
    /* width: 55%; */
    /* border-right: 1px solid #D9D9D9FF; */
}
.main .song_box{
	/* max-height: 80vh;
	overflow: auto; */
	border-radius: 5px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    align-items: center;
	/* background-color: #55aaff !important; */
}
.main .song_box .song_box_content{
    margin-left: 20px;
}
.main .song_box .song_box_img{
    width: 10%;
}
.main .song_box .song_box_img img{
    width: 100%;
}
/* .wrap .right{
    width: 20%;
} */
.right .content{
    padding: 15px;
}
.right .right_img{
    width: 100%;
	text-align: center;
}
.right .right_img img{
    width:60%;
	/* margin-left: 10%; */
	border-radius: 50%;
	transition: transform 0.5s linear; /* 平滑旋转效果 */
	filter: brightness(90%);
}
.right .right_img img.rotate {
  animation: spin 12s linear infinite; /* 旋转动画 */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.right .right_box1{
    margin-top: 10px ;
}
.right .right_box1 .right_word1{
    font-size: 1.3vw;
	text-align: center;
}
.right .right_box1 .right_word3{
    font-size: 1vw;
    color: #BFC2CC;
}

/* style.css */
.audio-player {
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color: #fefefe;
    margin: 20px auto;
}

.controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

#playPause {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.progress-bar {
    flex-grow: 1;
    height: 5px;
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
}

.progress {
    height: 100%;
    background-color: #007bff;
    border-radius: 5px;
    width: 0%;
}

</style>

